import { RootState } from '../store';
import { useSelector } from 'react-redux';

const useAuthApp = () => {
  const userState = useSelector((state: RootState) => state.user);

  const user = userState.user;
  const accessToken = userState.accessToken;
  const isAuthenticated = user && accessToken;

  return {
    isAuthenticated
  };
};

export default useAuthApp;
