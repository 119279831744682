import { Category } from './../../types/index';
import { Account, Transaction } from '../../types/index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type CreateTransactionMode = { mode: 'create' };
export type EditTransactionMode = { mode: 'edit'; transaction: Transaction };
export type DeleteTransactionMode = { mode: 'delete'; transactionId: number };

export type TransactionActionMode =
  | CreateTransactionMode
  | EditTransactionMode
  | DeleteTransactionMode;

export type MonthlyTransaction = {
  total_amount_expense: number;
  total_amount_income: number;
  month: number;
  year: number;
};

export interface TransactionsState {
  isLoading: boolean;
  transactions: Transaction[];
  account: Account | null;
  actionMode: TransactionActionMode | null;
  formAccounts: Account[];
  formCategories: Category[];
  error: string | false;
  hasMore: boolean;
  monthlyTransactions: MonthlyTransaction[];
  page: number;
}

const initialAccountState: TransactionsState = {
  isLoading: false,
  transactions: [],
  account: null,
  actionMode: null,
  formAccounts: [],
  formCategories: [],
  hasMore: true,
  page: 1,
  monthlyTransactions: [],
  error: false
};

const transactionsSlice = createSlice({
  name: 'accounts',
  initialState: initialAccountState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setTransactions: (state, action: PayloadAction<Transaction[]>) => {
      state.transactions = action.payload;
    },
    fetchTransactionsSuccess(state, action) {
      state.isLoading = false;
      state.transactions = [...state.transactions, ...action.payload];
    },
    addTransaction: (state, action: PayloadAction<Transaction>) => {
      state.transactions.push(action.payload);
    },
    setActionMode: (state, action: PayloadAction<TransactionActionMode | null>) => {
      if (action.payload == null) {
        state.formCategories = [];
        state.formAccounts = [];
      }
      state.actionMode = action.payload;
    },
    setFormAccounts: (state, action: PayloadAction<Account[]>) => {
      state.formAccounts = action.payload;
    },
    setFormCategories: (state, action: PayloadAction<Category[]>) => {
      state.formCategories = action.payload;
    },
    setAccount: (state, action: PayloadAction<Account | null>) => {
      state.account = action.payload;
    },
    updateTransaction: (state, action: PayloadAction<Transaction>) => {
      const updatedTransaction = action.payload;
      state.transactions = state.transactions.map((transaction) =>
        transaction.id === updatedTransaction.id ? updatedTransaction : transaction
      );
    },
    deleteTransaction(state, action: PayloadAction<number>) {
      const transactionIdToDelete = action.payload;
      state.transactions = state.transactions.filter(
        (transaction) => transaction.id !== transactionIdToDelete
      );
    },
    setHasMore(state, action: PayloadAction<boolean>) {
      state.hasMore = action.payload;
    },
    setNextPage(state) {
      state.page += 1;
    },
    setMonthlyTransactions(state, action: PayloadAction<MonthlyTransaction[]>) {
      state.monthlyTransactions = action.payload;
    },
    resetTransactionsState: () => initialAccountState
  }
});

export const {
  setIsLoading,
  setActionMode,
  setTransactions,
  addTransaction,
  setFormAccounts,
  setFormCategories,
  setAccount,
  updateTransaction,
  deleteTransaction,
  setHasMore,
  fetchTransactionsSuccess,
  setNextPage,
  setMonthlyTransactions,
  resetTransactionsState
} = transactionsSlice.actions;

export const selectTransactions = (state: { transactions: TransactionsState }) =>
  state.transactions;

export default transactionsSlice.reducer;
